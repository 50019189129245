import * as React from 'react';
import { translate } from 'react-i18next';
const dataAgency = require('../../../../content/setup/info/info.json');

const style = `body { font-family:  Arial, Helvetica, sans-serif; font-size:12px; background: #F5F5F5;}
    .wrapper {background: #FFF;margin: auto;width: 690px;}
    td{font-family:  Arial, Helvetica, sans-serif; font-size:12px;}
    tr.header {background: #8EC73E;}
    td.logo{text-align: center;padding: 30px 5px; background: #8EC73E;}
    td.content{padding: 0px 40px;padding-bottom:40px;padding-top: 45px;border-bottom: 6px solid #8EC73E;}
    .title { font-weight: bold; }            
    table { border-collapse:collapse; }
    tr.group td {padding: 12px 5px;font-weight: bold;font-size: 13px;height: 22px;line-height: 20px;}
    td { padding: 2px 5px; }
    td.name { border-bottom: 1px solid #E6E6E6;padding-top: 10px;}
    td.value { border-bottom: 1px solid #E6E6E6;padding-top: 10px; }
    .style1 {font-size: 22px;color: #8EC73E;text-transform: uppercase;font-weight: normal;letter-spacing: 1px;}
    #last1, #last2{border-bottom:2px solid gray;}
    `;

interface IContactFormProps {
    i18n?: any;
    t?: any;
    data: any;
    type: string;
}

class DemandRegisterMailComponent extends React.Component<IContactFormProps> {
    constructor(props: IContactFormProps) {
        super(props);
    }

    public componentDidMount() {

    }

    public renderContactConstruction() {
        const { t } = this.props;
        return <tbody>
            <tr className='group'><td colSpan={2} >{t('PROPERTY_INFORMATION')}</td> </tr>
            <tr>
                <td className='name'>{t('ID')}  : </td>
                <td className='value'><a href={this.props.data.linkObject}>{this.props.data.objectID}</a></td>
            </tr>
            <tr>
                <td className='name'>{t('PROPERTY_TYPES')} :  </td>
                <td className='value'>{this.props.data.propertyType}</td>
            </tr>
            <tr>
                <td className='name'>{t('ZIP')} :</td>
                <td className='value'>{this.props.data.propertyZip}</td>
            </tr>
            <tr>
                <td className='name' id='last1'>{t('CITY')} :</td>
                <td className='value' id='last2'>{this.props.data.propertyCity}</td>
            </tr>
        </tbody>;
    }

    public renderContactDemand() {
        const { t } = this.props;
        return <tbody><tr className='group'>
            <td colSpan={2} >{t('PROPERTY_TO_VALUATE')}</td>
        </tr>
            <tr>
                <td className='name'>{t('PROPERTY_GOAL_TEXT')} :</td>
                <td className='value'>{this.props.data.selectedDemandGoalName}</td>
            </tr>
            <tr>
                <td className='name'>{t('ADDRESS')}  : </td>
                <td className='value'>{this.props.data.txtDemandStreet}</td>
            </tr>
            <tr>
                <td className='name'>{t('ZIP')} : </td>
                <td className='value'>{this.props.data.txtDemandPostCode}</td>
            </tr>
            <tr>
                <td className='name'>{t('CITY')} : </td>
                <td className='value'>{this.props.data.txtDemandCity}</td>
            </tr>
            <tr>
                <td className='name'>{t('PROPERTY_TYPES')} :  </td>
                <td className='value'>{this.props.data.selectedDemandWebIDName}</td>
            </tr>
            <tr>
                <td className='name' id='last1'>{t('REMARK')} </td>
                <td className='value' id='last2'>{this.props.data.txtRemark}</td>
            </tr></tbody>;
    }

    public renderContactMeMail() {
        const { t } = this.props;
        return <tbody><tr className='group'><td colSpan={2} >{t('PROPERTY_INFORMATION')}</td> </tr>
            <tr>
                <td className='name'>{t('ID')}  : </td>
                <td className='value'>{this.props.data.objectID}</td>
            </tr>
            <tr>
                <td className='name'>{t('PROPERTY_TYPES')} :  </td>
                <td className='value'>{this.props.data.propertyType}</td>
            </tr>
            <tr>
                <td className='name'>{t('PROPERTY_GOAL_TEXT')} :</td>
                <td className='value'>{this.props.data.propertyGoal}</td>
            </tr>
            <tr>
                <td className='name'>{t('ADDRESS')} :</td>
                <td className='value'>{this.props.data.propertyStreet} {this.props.data.propertyHouseNumber}</td>
            </tr>
            <tr>
                <td className='name'>{t('ZIP')} :</td>
                <td className='value'>{this.props.data.propertyZip}</td>
            </tr>
            <tr>
                <td className='name' id='last1'>{t('CITY')} :</td>
                <td className='value' id='last2'>{this.props.data.propertyCity}</td>
            </tr></tbody>;
    }

    public renderDemandRegisterMail() {
        const { t } = this.props;
        return <tbody>
            <tr className='group'>
                <td colSpan={2} >{t('PROPERTY_TO_VALUATE')}</td>
            </tr>
            <tr>
                <td className='name'>{t('PROPERTY_GOAL_TEXT')} :</td>
                <td className='value'>{this.props.data.selectedDemandGoalName}</td>
            </tr>
            <tr>
                <td className='name'>{t('PROPERTY_TYPE_TEXT')}  : </td>
                <td className='value'>{this.props.data.TypesNameChecked}</td>
            </tr>
            <tr>
                <td className='name'>{t('GEOGRAPHICS')}  : </td>
                <td className='value'>{this.props.data.GeosNameChecked}</td>
            </tr>
            <tr>
                <td className='name'>{t('PRICE_MIN')} : </td>
                <td className='value'>{this.props.data.txtPropertyPriceMin}</td>
            </tr>
            <tr>
                <td className='name'>{t('PRICE_MAX')} : </td>
                <td className='value'>{this.props.data.txtPropertyPriceMax}</td>
            </tr>
            <tr>
                <td className='name' id='last1'>{t('NUMBER_OF_BEDROOMS_MIN')} </td>
                <td className='value' id='last2'>{this.props.data.txtPropertyMinRooms}</td>
            </tr>
        </tbody>;
    }

    public render() {
        const { t } = this.props;
        return (
            <html>
                <head>
                    <title key='contact_demand'></title>
                    <meta httpEquiv='Content-Type' content='text/html; charset=iso-8859-1' />
                </head>
                <style>
                    {style}
                </style>
                <body>
                    <table style={{ width: '100%', height: '100%' }} >
                        <tbody>
                            <tr>
                                <td className='logo'>
                                    <a href={this.props.data.website}><img src={`${this.props.data.website}/layouts/images/logo.png`} height='142px' /></a>
                                </td>
                            </tr>
                            <tr>
                                <td className='content'>
                                    <h3 className='style1'>{this.props.data.subject}</h3>
                                    <table id='tab2' cellSpacing='0' cellPadding='0' style={{ width: '100%', border: '0' }} >
                                        <tbody>
                                            <tr className='group'><td colSpan={2} >{t('PERSIONAL_INFORMATION')}</td> </tr>
                                            <tr>
                                                <td className='name' style={{ width: '200px' }}>{t('TITLE')} :  </td>
                                                <td className='value'>{this.props.data.selectedTitle}</td>
                                            </tr>
                                            <tr>
                                                <td className='name'>{t('FIRST_NAME')} : </td>
                                                <td className='value'>{this.props.data.txtFirstName}</td>
                                            </tr>
                                            <tr>
                                                <td className='name'>{t('LAST_NAME')} : </td>
                                                <td className='value'>{this.props.data.txtLastName}</td>
                                            </tr>
                                            <tr>
                                                <td className='name'>{t('PHONE_NUMBER')} : </td>
                                                <td className='value'>{this.props.data.txtPhone}</td>
                                            </tr>
                                            <tr>
                                                <td className='name'>{t('EMAIL')} : </td>
                                                <td className='value'>{this.props.data.txtEmail}</td>
                                            </tr>
                                            {!!this.props.data.txtStreet && <tr>
                                                <td className='name'>{t('ADDRESS')} : </td>
                                                <td className='value'>{this.props.data.txtHouseNumber} {this.props.data.txtStreet}</td>
                                            </tr>}
                                            {!!this.props.data.txtPostCode && <tr>
                                                <td className='name'>{t('ZIP')} : </td>
                                                <td className='value'>{this.props.data.txtPostCode}</td>
                                            </tr>}
                                            {!!this.props.data.txtCity && <tr>
                                                <td className='name'>{t('CITY')} :</td>
                                                <td className='value'>{this.props.data.txtCity}</td>
                                            </tr>}
                                            {!!this.props.data.txtRemark && <tr>
                                                <td className='name'>{t('REMARK')} </td>
                                                <td className='value'>{this.props.data.txtRemark}</td>
                                            </tr>}
                                            {!!this.props.data.linkProperty && this.props.type === 'send-to-friend' && <tr>
                                                <td className='name'>{t('LINK')} </td>
                                                <td className='value'><a href={this.props.data.linkProperty}>Click here</a></td>
                                            </tr>}
                                            {this.props.type === 'contact-construction' && this.renderContactConstruction()}
                                            {this.props.type === 'contact-me' && this.renderContactMeMail()}
                                            {this.props.type === 'demand-register' && this.renderDemandRegisterMail()}
                                            {this.props.type === 'contact-demand' && this.renderContactDemand()}
                                        </tbody>
                                    </table>
                                    <br />
                                    <br />
                                    <div className='agency-info'>
                                        {dataAgency.street_houseNumber} - {dataAgency.zip_city} - Tel: {dataAgency.phoneNumber} - Email: <a href={`mailto:${dataAgency.email}`}>{dataAgency.email}</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </body>
            </html>
        );
    }
}

export default translate('translations')(DemandRegisterMailComponent);
