import axios from 'axios';
import * as mailConfig from './mail-config';

const regexNumber = /\d/g;
const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

export function validation(nameField: string, value1: string, validationArray: string[], value2?: string) {
    for (const vali of validationArray) {
        switch (vali) {
            case 'isEmpty':
                if (value1 === '') {
                    return `The field is required`;
                }
                break;
            case 'isEmail':
                if (!value1.match(regexEmail) && value1 !== '') {
                    if (value1.length >= 10) {
                        value1 = value1.substring(0, 9) + '...';
                    }
                    return `${value1} is not a valid email`;
                }
                break;
            case 'isNumber':
                if (!value1.match(regexNumber) && value1 !== '') {
                    if (value1.length >= 10) {
                        value1 = value1.substring(0, 9) + '...';
                    }
                    return `${value1} is not a valid number`;
                }
                break;
            case 'minLength':
                if (value1.trim().length < 6 && value1 !== '') {
                    return `Password must be at least 6 characters long`;
                }
                break;
            case 'passEqual':
                if (value1 !== value2 && value1 !== '') {
                    return `Passwords are not equal`;
                }
                break;
            default:
                return false;
        }
    }
    return false;
}

export function validateException(nameElement: string, resultValidation: any) {

    console.log({ resultValidation });

    const element = document.getElementById(nameElement);

    if (typeof element !== 'undefined' && element !== null) {

        const parentEl = element.parentElement;
        const validateElCheck = parentEl!.getElementsByClassName('form-text text-danger');

        if (resultValidation === false) {

            element.removeAttribute('class');

            // check error isset
            if (validateElCheck.length > 0) {
                validateElCheck[0].innerHTML = '';
            }

        } else {

            element.setAttribute('class', 'invalid');
            element.focus();

            // check error isset
            if (validateElCheck.length > 0) {
                validateElCheck[0].innerHTML = resultValidation;
            } else {
                const validateEl = document.createElement('span');
                validateEl.setAttribute('class', 'form-text text-danger');
                validateEl.setAttribute('style', 'font-size: 12px');
                validateEl.innerHTML = resultValidation;
                if (parentEl !== null) {
                    parentEl.appendChild(validateEl);
                }
            }
        }

    }
}

export function formValidate(state: any[any]) {
    let error = 0;
    for (const val of state['formValidateEmpty']) {
        const result = validation(val, state[val], ['isEmpty']);
        validateException(val, result);
        if (result !== false) {
            error++;
        }
    }
    if (error === 0) {
        return false;
    } else {
        return true;
    }
}

export async function sendMail(data: any, func: string) {
    try {
        const mailData = {
            'From': 'noreply@omnicasa.com',
            'To': 'trungdq@omnicasa.com',
            'Bcc': 'adil@omnicasa.com',
            'HtmlBody': data.SendMail.HtmlBody,
            'Subject': data.ContactData.Subject
        };

        const payload = {
            SendMail: mailData,
            ContactData: data.ContactData,
        };

        const params = {
            'customer-id': mailConfig.CustomerID,
            ...data.headers
        };

        const headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, X-Auth-Token, Accept'
        };

        const result = await axios.post(`${mailConfig.API_URL_SEND_MAIL}${func}`, payload, {
            params: params,
            headers: headers
        });
        return result;
    } catch (err) {
        return err;
    }
}
