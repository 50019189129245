import * as React from 'react';
// import Loading from 'react-loading-components';

interface IBackgroundLoadingProps {
    show: boolean;
}

class BackgroundLoading extends React.Component<IBackgroundLoadingProps> {
    constructor(props: IBackgroundLoadingProps) {
        super(props);
    }

    public render() {
        return (
            <div>
                {this.props.show && <div style={{ margin: 'auto', position: 'absolute', top: '0', left: '0', bottom: '-18px', right: '0', backgroundColor: '#000000', opacity: 0.8, zIndex: 9999 }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', margin: '0 auto' }}>
                        <div style={{ textAlign: 'center' }}><img src='/layouts/images/loading.gif' className='loading-image' alt='loading-image' /></div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default BackgroundLoading;
