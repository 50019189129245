import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { translate } from 'react-i18next';
import { Link, navigate } from 'gatsby';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as formHelper from './form-helper/form-helper';
import * as helper from '../../helper/helpers';
import * as Recaptcha from 'react-recaptcha';
import ContactMail from './mail_templates/mail-teamplte';
import Loading from '../GeneralComponent/background-loading';

const _dataJson = require('../../../content/contact/demand-register/demand-register.json');

interface IDemandRegisterFormProps {
    i18n?: any;
    t?: any;
    TypeList?: any;
    GeoList?: any;
}

interface IDemandRegisterFormState {
    TypeChecked: any;
    TypesNameChecked: any;
    TypeExpanded: any;
    TypeNodes: any;
    GeoNodes: any;
    GeoChecked: any;
    GeosNameChecked: any;
    GeoExpanded: any;
    selectedDemandGoal: string;
    selectedDemandGoalName: string;
    selectedTitle: string;
    txtLastName: string;
    txtFirstName: string;
    txtPhone: string;
    txtAddress: string;
    txtGSM: string;
    txtPostCode: string;
    txtCity: string;
    txtEmail: string;
    txtPassword: string;
    txtPasswordConfirm: string;
    txtPropertyMinRooms: string;
    txtPropertyPriceMin: string;
    txtPropertyPriceMax: string;
    txtRemark: string;
    blnNewsLetter: boolean;
    reCaptchaResponse: string;
    useCaptcha: boolean;
    subject: string;
    mail_key: string;
    website: string;
    send_success: boolean;
    mailSendStatus: string;
    formValidateEmpty: any[];
}

class DemandRegisterFormComponent extends React.Component<IDemandRegisterFormProps, IDemandRegisterFormState> {
    private recaptchaInstance = React.createRef<{}>();
    constructor(props: IDemandRegisterFormProps) {
        super(props);
        const { t } = this.props;
        this.state = {
            TypeChecked: [],
            TypesNameChecked: [],
            TypeExpanded: [],
            TypeNodes: [],
            GeoNodes: [],
            GeoChecked: [],
            GeosNameChecked: [],
            GeoExpanded: [],
            website: '',
            subject: t('MAIL_SUBJECT_DEMAND_REGISTER'),
            mail_key: 'DEMAND_REGISTER',
            useCaptcha: true,
            selectedTitle: t('MR'),
            selectedDemandGoal: '0',
            selectedDemandGoalName: '',
            formValidateEmpty: ['txtLastName', 'txtFirstName', 'txtPhone', 'txtEmail', 'txtPassword', 'txtPasswordConfirm'],
            txtLastName: '',
            txtFirstName: '',
            txtPhone: '',
            txtAddress: '',
            txtGSM: '',
            txtPostCode: '',
            txtCity: '',
            txtEmail: '',
            txtPassword: '',
            txtPasswordConfirm: '',
            txtPropertyMinRooms: '',
            txtPropertyPriceMin: '',
            txtPropertyPriceMax: '',
            txtRemark: '',
            blnNewsLetter: false,
            reCaptchaResponse: '',
            send_success: false,
            mailSendStatus: ''
        };
    }

    public componentDidMount() {
        const TypeNodes = this.props.TypeList;
        const GeoNodes = this.props.GeoList;
        this.setState({ TypeNodes, GeoNodes });
    }

    public componentWillReceiveProps(nextProps: any) {
        const TypeNodes = nextProps.TypeList;
        const GeoNodes = nextProps.GeoList;
        this.setState({ TypeNodes, GeoNodes });
    }

    public bindListWithoutNode(List: any) {
        const nodes: any[] = [];
        if (List.length > 0) {
            List.forEach((item: any) => {
                nodes.push({
                    id: item.node.ID,
                    parentid: item.node.ParentID,
                    value: item.node.ID,
                    label: item.node.Name
                });
            });
        }
        return nodes;
    }

    public handleUserInput(e: any, validation: string[]) {
        const name = e.target.id;
        const value = e.target.value;
        let result: any = false;
        if (validation.length > 0) {
            if (name === 'txtPasswordConfirm') {
                result = formHelper.validation(name, value, validation, this.state.txtPassword);
            } else {
                result = formHelper.validation(name, value, validation);
            }
            formHelper.validateException(name, result);
            if (result === false) {
                // @ts-ignore
                this.setState({ [name]: value });
            } else {
                // @ts-ignore
                this.setState({ [name]: '' });
            }
        } else {
            // @ts-ignore
            this.setState({ [name]: value });
        }
    }

    public handleblnNewsLetter(e: any) {
        this.setState({ blnNewsLetter: !this.state.blnNewsLetter });
    }

    public handleTypesCheck(checked: any) {
        const TypesName: any[] = [];
        checked.forEach((id: any) => {
            const str = id.split('-');
            TypesName.push(str[1]);
        });
        this.setState({ TypeChecked: checked, TypesNameChecked: TypesName.join(',\n') });
    }

    public handleGeosCheck(checked: any) {
        const GeosName: any[] = [];
        checked.forEach((id: any) => {
            const str = id.split('-');
            GeosName.push(str[1]);
        });
        this.setState({ GeoChecked: checked, GeosNameChecked: GeosName.join(',\n') });
    }

    public async onSubmit(event: any) {
        const { t } = this.props;
        event.preventDefault();
        const validate = formHelper.formValidate(this.state);
        if (validate === false) {
            await this.setState({ mailSendStatus: 'sending', selectedDemandGoalName: t(helper.getNameGoal((this.state.selectedDemandGoal))) });
            if (this.state.useCaptcha) {
                // @ts-ignore
                this.recaptchaInstance.execute();
                const check_resCaptcha = setInterval(async () => {
                    if (this.state.reCaptchaResponse !== '' && this.state.reCaptchaResponse !== null) {
                        // const jsonData = JSON.stringify(this.state);
                        clearInterval(check_resCaptcha);
                        const objectToSend = this.prepareDataToSend();
                        const res: any = await formHelper.sendMail(objectToSend, 'demand-register');
                        if (res.status === 200) {
                            await this.setState({ mailSendStatus: 'sent' });
                            navigate(`/feed-back?success=1&f=${this.state.mail_key.toLowerCase()}`);
                        } else {
                            await this.setState({ mailSendStatus: 'sent' });
                            navigate(`/feed-back?success=0&f=${this.state.mail_key.toLowerCase()}`);
                        }
                    }
                }, 1000);
            } else {
                const objectToSend = this.prepareDataToSend();
                const res: any = await formHelper.sendMail(objectToSend, 'demand-register');
                if (res.status === 200) {
                    await this.setState({ mailSendStatus: 'sent' });
                    navigate(`/feed-back?success=1&f=${this.state.mail_key.toLowerCase()}`);
                } else {
                    await this.setState({ mailSendStatus: 'sent' });
                    navigate(`/feed-back?success=0&f=${this.state.mail_key.toLowerCase()}`);
                }
            }
        }
    }

    public onLoadRecaptcha() {
        console.log('reCaptcha ready !!!');
    }

    public verifyCallbackCaptcha(response: any) {
        this.setState({ reCaptchaResponse: response });
    }

    public getGeographicName(id: number): string {
        const geo = this.props.GeoList.find((item: any) => item.node.ID.toString() === id.toString());
        return geo.node.Name;
    }

    public getTypeOfPropertiesName(id: number): string {
        const type = this.props.TypeList.find((item: any) => item.node.ID.toString() === id.toString());
        return type.node.Name;
    }

    public prepareDataToSend() {
        const data = {
            ContactData: {
                Address: this.state.txtAddress,
                Name: this.state.txtLastName,
                FirstName: this.state.txtFirstName,
                AddressTitle: this.state.selectedTitle,
                City: this.state.txtCity,
                Zip: this.state.txtPostCode,
                GSM: this.state.txtGSM,
                Email: this.state.txtEmail,
                Password: this.state.txtPassword,
                PhoneNumber: this.state.txtPhone,
                Content: this.state.txtRemark,
                Subject: this.state.subject,
                IsReceivedEmail: this.state.blnNewsLetter,
                TypeOfPropertyIDs: this.state.TypeChecked.join(','),
                GeographicIDs: this.state.GeoChecked.join(','),
                MinRooms: this.state.txtPropertyMinRooms,
                PriceMin: this.state.txtPropertyPriceMin,
                PriceMax: this.state.txtPropertyPriceMax,
            },
            headers: {
                ReCaptchaResponse: this.state.reCaptchaResponse,
                UseCaptcha: this.state.useCaptcha,
            },
            SendMail: {
                HtmlBody: ReactDOMServer.renderToString(<ContactMail type='demand-register' data={this.state} />)
            }
        };
        return data;
    }

    public render() {
        const { t, i18n } = this.props;
        const dataContact = _dataJson;
        return (
            <div className='site-contact'>
                <Loading show={this.state.mailSendStatus === 'sending' ? true : false} />
                {(this.state.mailSendStatus === '' || this.state.mailSendStatus === 'sending') && <div id='ContactRegion'>
                    <h2>{helper.getContentWithLanguage(dataContact.titlePage, i18n.language)}</h2>
                    <div className='contact-desc' style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: helper.getContentWithLanguage(dataContact.body, i18n.language) }}></div>
                    <br />
                    <legend><h4>PERSOONLIJKE GEGEVENS</h4></legend>
                    <form>
                        <div className='row-fluid'>
                            <div className='row-fluid'>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='selTitle' className='control-label'>{t('TITLE')} <span className='required'>*</span> :</label>
                                        <div className='controls'>
                                            <select
                                                id='selectedTitle'
                                                name='FIELD[AddressTitle]'
                                                value={this.state.selectedTitle}
                                                onChange={event => this.handleUserInput(event, ['isEmpty'])}>
                                                <option value={t('MR')}>{t('MR')}</option>
                                                <option value={t('MS')}>{t('MS')}</option>
                                                <option value={t('MISS')}>{t('MISS')}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtFirstName' className='control-label'>{t('FIRST_NAME')} <span className='required'>*</span> :</label>
                                        <div className='controls'>
                                            <input type='text'
                                                id='txtFirstName'
                                                name='FIELD[FirstName]'
                                                onChange={event => this.handleUserInput(event, ['isEmpty'])} />
                                        </div>
                                    </div>
                                </div>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtLastName' className='control-label'>{t('LAST_NAME')} <span className='required'>*</span> :</label>
                                        <div className='controls'>
                                            <input type='text'
                                                id='txtLastName'
                                                name='FIELD[Name]'
                                                onChange={event => this.handleUserInput(event, ['isEmpty'])} />
                                        </div>
                                    </div>
                                </div>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtPhone' className='control-label'>{t('PHONE_NUMBER')} <span className='required'>*</span> :</label>
                                        <div className='controls'>
                                            <input type='text'
                                                id='txtPhone'
                                                name='FIELD[PhoneNumber]'
                                                onChange={event => this.handleUserInput(event, ['isEmpty', 'isNumber'])} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row-fluid'>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtGSM' className='control-label'>{t('GSM')} :</label>
                                        <div className='controls'>
                                            <input type='text'
                                                id='txtGSM'
                                                name='FIELD[GSM]'
                                                onChange={event => this.handleUserInput(event, ['isNumber'])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtAddress' className='control-label'>{t('ADDRESS')}  :</label>
                                        <div className='controls'>
                                            <input type='text'
                                                id='txtAddress'
                                                name='FIELD[Address]'
                                                onChange={event => this.handleUserInput(event, [])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtPostCode' className='control-label'>{t('ZIP')}  :</label>
                                        <div className='controls'>
                                            <input type='text'
                                                id='txtPostCode'
                                                name='FIELD[ZIP]'
                                                onChange={event => this.handleUserInput(event, ['isNumber'])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtCity' className='control-label'>{t('CITY')}  :</label>
                                        <div className='controls'>
                                            <input type='text'
                                                id='txtCity'
                                                name='FIELD[City]'
                                                onChange={event => this.handleUserInput(event, [])}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtEmail' className='control-label'>{t('EMAIL')}  <span className='required'>*</span> :</label>
                                        <div className='controls'>
                                            <input type='text'
                                                id='txtEmail'
                                                name='FIELD[Email]'
                                                onChange={event => this.handleUserInput(event, ['isEmpty', 'isEmail'])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtEmail' className='control-label'>{t('PASSWORD')} <span className='required'>*</span> :</label>
                                        <div className='controls'>
                                            <input type='password'
                                                id='txtPassword'
                                                name='FIELD[Password]'
                                                onChange={event => this.handleUserInput(event, ['isEmpty', 'minLength'])}
                                            />
                                        </div>
                                    </div></div>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtEmail' className='control-label'>{t('PASSWORD_CONFIRM')} <span className='required'>*</span> :</label>
                                        <div className='controls'>
                                            <input type='password'
                                                id='txtPasswordConfirm'
                                                name='FIELD[PasswordConfirm]'
                                                onChange={event => this.handleUserInput(event, ['isEmpty', 'minLength', 'passEqual'])}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <legend><h4>{t('SEARCH_DATA')}</h4></legend>
                            <div className='row-fluid'>
                                <div className='row-fluid'>
                                    <div className='span3'>
                                        <div className='control-group'>
                                            <label area-label='selPropertyGoal' className='control-label'>{t('PROPERTY_GOAL_TEXT')} <span className='required'>*</span> :</label>
                                            <div className='controls'>
                                                <select
                                                    id='selectedDemandGoal'
                                                    name='FIELD[DemandGoal]'
                                                    value={this.state.selectedDemandGoal}
                                                    onChange={event => this.handleUserInput(event, [])}
                                                >
                                                    <option value='0'>{t('FOR_SALE')}</option>
                                                    <option value='1'>{t('FOR_RENT')}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='span3'>
                                        <div className='control-group'>
                                            <label area-label='txtStreet' className='control-label'>{t('PROPERTY_ROOM_MIN_TEXT')}  :</label>
                                            <div className='controls'>
                                                <input type='text'
                                                    id='txtPropertyMinRooms'
                                                    name='FIELD[PropertyMinRooms]'
                                                    onChange={event => this.handleUserInput(event, ['isNumber'])}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='span3'>
                                        <div className='control-group'>
                                            <label area-label='txtStreet' className='control-label'>{t('PROPERTY_PRICE_MIN_TEXT')}  :</label>
                                            <div className='controls'>
                                                <input type='text'
                                                    id='txtPropertyPriceMin'
                                                    name='FIELD[PropertyPriceMin]'
                                                    onChange={event => this.handleUserInput(event, ['isNumber'])}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='span3'>
                                        <div className='control-group'>
                                            <label area-label='txtStreet' className='control-label'>{t('PROPERTY_PRICE_MAX_TEXT')}  :</label>
                                            <div className='controls'>
                                                <input type='text'
                                                    id='txtPropertyPriceMax'
                                                    name='FIELD[PropertyPriceMax]'
                                                    onChange={event => this.handleUserInput(event, ['isNumber'])}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtStreet' className='control-label'>{t('PROPERTY_TYPE_TEXT')}  :</label>
                                        <div className='controls'>
                                            <div className='treeview-container'>
                                                <CheckboxTree
                                                    nodes={this.state.TypeNodes}
                                                    checked={this.state.TypeChecked}
                                                    expanded={this.state.TypeExpanded}
                                                    nativeCheckboxes={true}
                                                    showNodeIcon={false}
                                                    onCheck={this.handleTypesCheck.bind(this)}
                                                    onExpand={(expanded: any) => this.setState({ TypeExpanded: expanded })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='span3'>
                                    <div className='control-group'>
                                        <label area-label='txtStreet' className='control-label'>{t('PROPERTY_GEOGRAPHIC_TEXT')}  :</label>
                                        <div className='controls'>
                                            <div className='treeview-container'>
                                                <CheckboxTree
                                                    nodes={this.state.GeoNodes}
                                                    checked={this.state.GeoChecked}
                                                    expanded={this.state.GeoExpanded}
                                                    nativeCheckboxes={true}
                                                    showNodeIcon={false}
                                                    onCheck={this.handleGeosCheck.bind(this)}
                                                    onExpand={(expanded: any) => this.setState({ GeoExpanded: expanded })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'>
                                    <label><input id='blnNewsLetter' name='FIELD[IsReceivedEmail]' type='checkbox' onChange={this.handleblnNewsLetter.bind(this)} /> {t('NEWS_LETTER')}</label>
                                </div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'>
                                    {t('PRIVACY_STATEMENT_TEXT')} <Link to='/'> {t('PRIVACY_STATEMENT')}</Link>.
                            </div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'>
                                    {this.state.useCaptcha && <div style={{ width: '50%', margin: '0 auto', marginBottom: '30px' }}>
                                        <Recaptcha
                                            ref={(c: any) => { this.recaptchaInstance = c; }}
                                            sitekey='6LfnXnIUAAAAAN_2K5ZPuzi6JvamtdrV6oOSIWfN'
                                            render='explicit'
                                            badge='bottomright'
                                            size='invisible'
                                            hl={i18n.language}
                                            onloadCallback={this.onLoadRecaptcha.bind(this)}
                                            verifyCallback={this.verifyCallbackCaptcha.bind(this)}
                                        />
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <div className='buttons'>
                            <button type='button' className='btn' aria-label='send form' onClick={this.onSubmit.bind(this)} >{t('SUBMIT')} <i className='icon-black icon-ok-sign'></i></button>
                        </div>
                    </form>
                </div>}
            </div>
        );
    }
}

export default translate('translations')(DemandRegisterFormComponent);
