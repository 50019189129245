import * as React from 'react';
import Layout from '../components/GeneralComponent/layout';
import { translate } from 'react-i18next';
import * as helper from '../helper/helpers';
import DemandRegisterForm from '../components//FormComponent/demand-register';

interface IDemandRegisterPageProps {
    pageContext: any;
    i18n: any;
}

class DemandRegisterPage extends React.Component<IDemandRegisterPageProps> {
    constructor(props: IDemandRegisterPageProps) {
        super(props);
    }

    public render() {
        const { i18n, pageContext } = this.props;
        helper.setLanguageDefault(i18n.language);
        return (
        <Layout>
            <div id='content-row'>
                <div className='row-container'>
                    <div className='container-fluid'>
                        <DemandRegisterForm 
                        TypeList={!!pageContext.typeOfPropertyList ? pageContext.typeOfPropertyList[i18n.language] : []}
                        GeoList={!!pageContext.geographicList ? pageContext.geographicList[i18n.language]  : []}
                        />
                    </div>
                </div>
            </div>
        </Layout>);
    }
}

export default translate('translations')(DemandRegisterPage);
